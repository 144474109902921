String.prototype.capitalize = function () {
	return this.charAt(0).toUpperCase() + this.slice(1);
};
function animateCopy(element) {
	element.classList.add('copied');
	setTimeout(function () { element.classList.remove('copied'); }, 1500);
}
function iosCopyToClipboard(el) {
	var oldContentEditable = el.contentEditable,
		oldReadOnly = el.readOnly,
		range = document.createRange();

	el.contentEditable = true;
	el.readOnly = false;
	range.selectNodeContents(el);

	var s = window.getSelection();
	s.removeAllRanges();
	s.addRange(range);

	el.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.

	el.contentEditable = oldContentEditable;
	el.readOnly = oldReadOnly;

	document.execCommand('copy');
}

if (!navigator.cookieEnabled) {
	var contentMessage = "Cookies are blocked or not supported by your browser. You must enable cookies to log in.";
	swal({
		title: 'Warning!',
		text: contentMessage,
		type: 'warning',
		confirmButtonText: 'Ok'
	});
}

window.scrollTo(0, 0);
//SECTION
//History Page specific
$(window).on('load', function () {
	// script is loaded only on the history page
	if ((window.location.href.indexOf("history") != -1) && window.location.hash) {
		window.setTimeout(function () {

			var serviceCardId = window.location.hash.replace('#', '');
			if (isNaN(serviceCardId)) {
				return;
			}

			var elem = $('.service-card-item[data-booking-id=' + serviceCardId + ']');
			if (elem.length == 0) {
				return;
			}

			var md = new MobileDetect(window.navigator.userAgent); // Mobile Detect

			// check if we have a visible element ?
			// if not, display container containing the current card
			// and now we can scroll to it.
			if (!elem.is(':visible')) {
				elem.closest('.content-inner-holder').css('display', 'block');
			}

			var elementToClick = elem.find('[data-trigger="rate"]');
			var elementToScroll = 0;

			if (md.phone()) {
				elementToScroll = elem.find('.service-card-image-holder').first().offset().top - 85;
			} else {
				elementToScroll = elem.offset().top - 85;
			}

			$('html, body').animate({
				scrollTop: elementToScroll
			}, 800)
				.promise().done(
					function () {
						window.setTimeout(function () {
							elementToClick.trigger('click');
						}, 250);
					}
				);
		}, 1000);
	}
});

window.onload = function () {
	document.body.classList.remove('preload-transition-fix');
};

//Prevent safari loading from cache when back button is clicked
$(window).bind("pageshow", function (event) {
	if (event.originalEvent.persisted) {
		window.location.reload()
	}
});

//SECTION
//Jquery Function
$(function () {
	var bodyElement = $('body'),
		loaderElement = $('#loader-wrapper'),
		contentWrapper = $('.content-wrapper'),
		contentInnerWrapper = contentWrapper.find('.content-inner-wrapper'),
		fadeInDelayedElements = $('.fade-in-delayed'),
		sidebarButton = $('.sidebar-button'),
		sidebarElement = $('.sidebar-holder');


	//Service card variables
	$(document).on('click', function (event) {
		var eventTarget = $(event.target);

		if (!eventTarget.is('span[class*=remove]') &&
			!eventTarget.hasClass('tooltip-holder') &&
			!eventTarget.parents('.tooltip-holder').length &&
			!$('.tooltip-holder').is(':animated') &&
			$('.tooltip-holder').hasClass('visible')) {

			$('.tooltip-holder').stop().fadeOut(200, function () {
				$('.tooltip-holder').removeClass('visible');
			});
		}

		if (!eventTarget.attr('data-action') && !eventTarget.hasClass('sidebar-button') && $('[data-action-item]').hasClass('visible')) {
			$('[data-action-item].visible').parent().find('[data-action]').trigger('click');
		}

		if (!eventTarget.parents('.service-card-item.clone').length && !!$('.service-card-item.clone').length && $('.service-card-item.clone').hasClass('visible') && !eventTarget.parents('.modal').length && !$('.modal').is(':visible')) {
			$('.service-card-item.clone .service-card-close-btn').trigger('click');
		}

		if (!eventTarget.parents('.modal-content').length && !eventTarget.hasClass('modal-content') && !$('.modal').is(':animated')) {
			$('.service-card-item.clone').css({
				'z-index': '999'
			});
		}
	});


	$(document).on('click', '.modal .modal-content .icon-close', function () {
		$(document).trigger('click');
	});

	$(document).on('click', '.modal .modal-content .close', function () {
		$(document).trigger('click');
	});
	$(document).on('click', 'a:not([href="#"]):not([href="javascript:void(0)"]):not(.noloader):not(serviceos-web-root a):not(serviceos-ng-dialog a)', function (event) {
		if (event.ctrlKey) {
			return;
		}
		loaderElement.addClass('visible');
		if (navigator.userAgent.indexOf('SamsungBrowser') != -1) {
			setTimeout(() => {
				loaderElement.removeClass('visible');
				console.log('closed')
			}, 7000);
		};
	});

	$(document).on('click', '.social-buttons a', function (event) {
		loaderElement.removeClass('visible');
	});

	$(document).on('click', 'a[href="#"]', function (event) {
		event.preventDefault();
	});

	$(document).on('click', '.sidebar-menu-dropdown', function (event) {
		event.preventDefault();
		loaderElement.removeClass('visible');

		var currentDropdownButton = $(this),
			currentDropdownElement = currentDropdownButton.parents('.menu-item'),
			currentDropdownMenu = currentDropdownButton.parent().find('.account-settings-menu');

		if (!currentDropdownMenu.is(':animated')) {
			if (!currentDropdownMenu.is(':visible')) {
				currentDropdownButton.addClass('menu-visible');
				currentDropdownElement.addClass('menu-opened');

				currentDropdownMenu.stop().slideDown(300);
			} else {
				currentDropdownButton.removeClass('menu-visible');

				currentDropdownMenu.stop().slideUp(300, function () {
					currentDropdownElement.removeClass('menu-opened');
				});
			}
		}
	});

	$(document).on('click', '.alert .close', function () {
		var closeButton = $(this),
			alertElement = closeButton.parents('.alert');

		alertElement.remove();
	});

	$(document).on('click', '.share-input', function () {
		this.setSelectionRange(0, this.value.length);
	});

	$(document).on('click', '.copy-btn', function (e) {
		var target = e.target;
		var copyTarget = target.dataset.copytarget;
		var input = (copyTarget ? document.querySelector(copyTarget) : null);
		var inputOriginal = input.value;

		input.value = inputOriginal;
		input.setSelectionRange(0, input.value.length);
		if (input) {
			if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
				iosCopyToClipboard(input)
				animateCopy(target);
			} else if (input.select) {
				input.select();

				try {
					document.execCommand('copy');
					input.value = inputOriginal;
					input.select();
					animateCopy(target);
				}
				catch (err) {
					alert('please press Ctrl/Cmd+C to copy');
				}
			}
		}
	});

	//SECTION
	//inner wrapper
	if (contentInnerWrapper.length > 0) {
		contentInnerWrapper.on('click', function () {
			if (bodyElement.hasClass('sidebar-opened') && !sidebarElement.is(':animated')) {
				event.preventDefault();
				sidebarButton.eq(0).trigger('click')
			}
		});
	}

	//SECTION
	//Delayed fade in elements
	if (fadeInDelayedElements.length > 0) {
		var fadeInDelayedElementsLength = fadeInDelayedElements.length;

		//Could be simplified with a foreach in babel
		for (var i = 0; i < fadeInDelayedElementsLength; i += 1) {
			var element = $(fadeInDelayedElements[i]),
				elementIndex = (i * 2) / 10;

			element.css({
				'-webkit-animation-delay': elementIndex + 's',
				'-moz-animation-delay': elementIndex + 's',
				'animation-delay': elementIndex + 's'
			});
		}

		fadeInDelayedElements.addClass('fade-in');
	}
});

$('.new-sign-up').on('focus', 'input', function () {
	$('label[for="' + this.id + '"]').addClass('labelfocus');
});

$('.new-sign-up').on('blur', 'input', function () {
	$('label[for="' + this.id + '"]').removeClass('labelfocus');
});


// Manipulating the buttons opening FAQ iframe
var iFrameOpenButton = $('#open-iframe'),
	faqIframe = $('#help-iframe');

iFrameOpenButton.on('click', function () {
	var chatFrame = $('#chatFrame');

	if (chatFrame.is(':visible')) {
		chatFrame.hide();
	}

	if (!faqIframe.hasClass('show-iframe')) {
		chatFrame.hide();
		faqIframe.addClass('show-iframe');

		var chatButton = faqIframe.contents().find('#button-chat'),
			closeIframeBtn = faqIframe.contents().find('.dismiss-btn'),
			hiddenChatButton = $('#chat-button');

		chatButton.on('click', function () {
			hiddenChatButton[0].click();
			faqIframe.removeClass('show-iframe');
		});

		closeIframeBtn.on('click', function () {
			faqIframe.removeClass('show-iframe');
		});
	}
});

// Cookie functions
function getCookie(cname) {
	var name = cname + "=";
	var ca = document.cookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
}

//SECTION
// Manipulating the buttons for FAQ feedback
var faqButtons = $('.buttons-holder > .button');

faqButtons.each(function () {
	var button = $(this);

	button.on('click', function (e) {
		var user = getCookie("user-login");

		if (event.target.matches('.button-no') && user !== "") {
			var faqIframe = $('#help-iframe');

			if (faqIframe.is(':visible')) {
				faqIframe.removeClass('show-iframe');
			}

			return;
		}

		var primaryText = $('.primary-text'),
			secondaryText = $('.secondary-text'),
			buttonsContainer = $('.buttons-holder');

		primaryText.css({ 'transform': 'translateY(400%)' });
		secondaryText.css({ 'transform': 'translateY(0%)', 'margin-top': '20px' });
		buttonsContainer.hide();

		e.preventDefault();
	})

});

$(function () {
    var md = new MobileDetect(window.navigator.userAgent);

    let checkForSwiper = $('.check-swiper');
    let mainSwiperWrapperFlag = $('.main-swiper-wrapper');
    let allSwiperSliders = $('.main-swiper-wrapper p');
    let allSwiperSlidersDivs = $('.main-swiper-wrapper div.wa-slide');
    if (checkForSwiper.length) {
        checkForSwiper.addClass('swiper-container');
        mainSwiperWrapperFlag.addClass('swiper-wrapper');
        allSwiperSliders.addClass('swiper-slide');
        allSwiperSlidersDivs.addClass('swiper-slide');
        checkForSwiper.append('<div class="swiper-pagination"/>');
    }
    const isCommonSwiper = $('.common-swiper').length > 0;
    if (isCommonSwiper) {

        const slidesCount = $('.swiper-container.common-swiper .swiper-slide').length;

        new Swiper('.swiper-container.common-swiper', {
            direction: 'horizontal',
            pagination: slidesCount > 2 ? '.swiper-pagination' : false,
            loop: slidesCount > 2,
            speed: 500,
            paginationClickable: true,
            spaceBetween: 60,
            centeredSlides: true,
            autoplay: 6000,
            autoplayDisableOnInteraction: false
        });
    }
    const benefitSwiper = $('.benefit-swiper').length > 0;
    if (benefitSwiper) {

        const benefitSlidesCount = $('.swiper-container.benefit-swiper .swiper-slide').length;

        new Swiper('.swiper-container.benefit-swiper', {
            direction: 'horizontal',
            pagination: benefitSlidesCount > 2 ? '.swiper-pagination' : false,
            loop: benefitSlidesCount > 2,
            speed: 500,
            paginationClickable: true,
            spaceBetween: 60,
            centeredSlides: true,
            autoplay: 6000,
            autoplayDisableOnInteraction: false
        });
    }
});